<template>
  <PublicApply :post="post"></PublicApply>
</template>
<script>
import PublicApply from './PublicApply'
export default {
  name: 'Notarization',
  data () {
    return {
      post: {
        service_type: '预约-公证服务',
        type: '公证机构',
        service_name: '公证预约办理',
        info: '' +
          '<p>1、申请人向公证处申办公证，应如实告知所申请办理公证事项的有关情况，提供真实、合法、充分的证明材料。<p>' +
          '<p>2、申请人在网上上传的相关证明材料的原件需要在领取公证书之日向公证处公证人员出示并核对。</p>' +
          '<p>3、根据《中华人民共和国公证法》、《公证程序规则》等有关规定，申请人提供虚假证明材料骗取公证书的，将可能依法被追究民事责任、行政责任，甚至刑事责任。</p>'
      }
    }
  },
  components: {
    PublicApply
  }
}
</script>
<style scoped >
</style>
